<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-overlay
      :show="$store.state.lawtrendStore.newsLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-table
        ref="refInvoiceListTable"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #cell(title)="data">
          <a
            class="media-list media-bordered cursor-pointer"
            @click="
              $router.push({
                name: 'news-preview',
                params: { id: data.item.id }
              })
            "
          >
            <b-media class="p-0">
              <template #aside>
                <b-img
                  thumbnail
                  fluid
                  :src="
                    data.item.better_featured_image.media_details.sizes.medium
                      .source_url
                  "
                  blank-color="#ccc"
                  width="120"
                  alt="placeholder"
                  class="my-50"
                />
              </template>
              <h5
                class="media-heading text-primary"
                v-snip="{ lines: 2 }"
                v-html="data.item.title.rendered"
              ></h5>
              <p
                class="text-dark m-0"
                v-snip="{ lines: 1 }"
                v-html="data.item.excerpt.rendered"
              ></p>
              <small class="p-0 m-0 text-info">{{
                getSubDate(data.item.date)
              }}</small>
            </b-media>
          </a>
        </template>
      </b-table>
    </b-overlay>
    <hr class="my-0" />
    <div class="mx-2 my-1">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BImg,
  BAvatar,
  BModal,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip,
  VBModal
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import useNewsList from './useNewsList'

export default {
  components: {
    BCard,
    BRow,
    BModal,
    BCardText,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BImg,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple
  },
  methods: {
    getSubDate(date) {
      return moment(date).utc().fromNow()
    }
  },
  setup(props) {
    const statusOptions = ['Enabled', 'Disabled']

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData
    } = useNewsList({ type: props.type })

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      statusOptions,
      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>