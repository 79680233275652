<template>
  <div>
    <news_list></news_list>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import news_list from './news-list/news_list.vue'

export default {
  components: {
    BLink,
    BButton,
    BImg,
    news_list
  },
  data() {
    return {}
  }
}
</script>
